export const spacing = {
  menuHeight: '56px',
  XL: '192px',
  large: '96px',
  medium: '48px',
  xregular: '32px',
  regular: '24px',
  small: '16px',
  xsmall: '8px',
  xxsmall: '4px',
} as const;
export type Spacing = keyof typeof spacing;
export const isSpacing = (key: any): key is Spacing => {
  return typeof key === 'string' && !!spacing[key as Spacing];
};
